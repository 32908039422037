html, body {
  height: 100%;
}


body {
  margin: 0;
  background-color: whitesmoke;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


div {
    margin: 10px;
    border: 1px solid black;
}

#div_visualization {
    height:100%;
    padding: 0px;
}

#div_gui {
    /*background-color: dimgrey;*/
    

    display: flex;

}

#div_gui_entities {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
}

#div_gui_entities > * {
  width: 100%;
}

td > input {
  width: 100%;
}

td > button {
  width: 100%;
}
